<template>
	<div class="student-place-qcm-container">
		<div class="stats-container">
			<select v-model="selectedStudent">
				<option v-for="student in students" :key="student.id" :value="student.id" >{{ student.name }}</option>
			</select>
			<p v-if="selectedStudent"> 
				<span v-if="selectedStudentNote">Note: {{ selectedStudentNote }} / {{maxNote}}</span>
				<span v-else>QCM non répondu</span>
			
			</p>
		</div>
		<div class="questions-container">
			<div class="question-container" v-for="question in qcm.questions" :key="question.id">
				<h2> <span v-html="question.question"> </span> <span>({{question.point }} point<span v-if="question.point > 1">s</span>)</span></h2>
				<QCMImage v-if="question.image" :image="question.image" />
				<div class="answers-container">
					<div v-for="answer in question.answers" :key="answer.id">
						<p> 
							<input style="pointer-events: none;" type="checkbox" :checked="checkStudentAnswer(question.id,answer.id)" >&nbsp;
							<span v-html="answer.answer"></span>&nbsp;
							<span v-if="answer.is_correct">✅</span>
							<span v-else>❌</span> 
						</p> 
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { filteredQcmStudentAnswersService } from '../../../../../services/qcmStudentAnswersService'
import { getQCMService } from '../../../../../services/qcmServices'
import { filteredQCMStudentNotesService } from '../../../../../services/qcmStudentNotesServices'
import { allStudentsInClasseService } from '../../../../../services/studentsService'
import QCMImage from './QCMImage.vue'
export default{
	components:{
		QCMImage
	},
	data(){
		return{
			qcm: {},
			students: [],
			selectedStudent: null,
			selectedStudentNote: null,
			selectedStudentAnswers: [],
			maxNote: 0
		}
	},

	methods:{
		checkStudentAnswer(questionId,answerId){
			const answer = this.selectedStudentAnswers.find(question => question.question === questionId)


			if (!answer) {
				return false
			}

			if (answer.answers.includes(answerId)) {
				return true
			}

		},
		async getStudentNote(studentId){
			const filter = `qcm=${this.qcm.id}&student=${studentId}`
			const qcmNote = await filteredQCMStudentNotesService(filter)
			if (qcmNote.length === 0) {
				this.selectedStudentNote = null
				return
			}
			this.selectedStudentNote = qcmNote[0].note
		},
		async getStudentAnswers(studentId){
			const filter = `qcm=${this.qcm.id}&student=${studentId}`
			const qcmNote = await filteredQcmStudentAnswersService(filter)
			this.selectedStudentAnswers = qcmNote
		},
		async getQCM(){
			this.qcm = await getQCMService(this.$route.params.id)
			for (const question of this.qcm.questions) {
				this.maxNote += question.point
			}
		},
		async getStudents(){
			const res = await allStudentsInClasseService(this.qcm.classroom)
			this.students = res
		},
	},
	async created(){
		await this.getQCM()
		await this.getStudents()
		this.selectedStudent = this.students[0].id
	},

	watch:{
		selectedStudent(value){
			this.getStudentNote(value)
			this.getStudentAnswers(value)
		}
	}
}
</script>
<style scoped lang="scss">
@import '../../../../../assets/scss/student-place/qcm.scss';
</style>
